import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type ActionFilterInput = {
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  emitterName?: InputMaybe<EmitterNameEnum>;
  eosAccount?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  orderStatus?: InputMaybe<Array<InputMaybe<UpayOrderStatus>>>;
  status?: InputMaybe<Array<InputMaybe<ActionStatus>>>;
  type?: InputMaybe<ActionType>;
};

export type ActionNotFoundError = {
  __typename: 'ActionNotFoundError';
  errorMessage: Scalars['String']['output'];
};

export enum ActionStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  MoneyReceived = 'MONEY_RECEIVED',
  Paid = 'PAID',
  Processing = 'PROCESSING',
  Refunded = 'REFUNDED',
  Success = 'SUCCESS'
}

export enum ActionType {
  EmitCard = 'EMIT_CARD',
  ReplenishCard = 'REPLENISH_CARD'
}

export type ActivateCardInput = {
  cvv: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  trackInfo: Scalars['String']['input'];
};

export type ActivateCardOutput = ActionNotFoundError | ActivateCardSuccess | CardIsActiveError | CardNotFoundError | UnderMaintenanceError | WrongActionStatusError | WrongEmitterError;

export type ActivateCardSuccess = {
  __typename: 'ActivateCardSuccess';
  card: CardNode;
};

export type AddToBlacklistInput = {
  items: Array<InputMaybe<AddToBlacklistItem>>;
};

export type AddToBlacklistItem = {
  account: Scalars['String']['input'];
};

export type AddToBlacklistSuccess = {
  __typename: 'AddToBlacklistSuccess';
  items?: Maybe<Array<Maybe<BlacklistItemNode>>>;
};

export type BlacklistItemNode = {
  __typename: 'BlacklistItemNode';
  account: Scalars['String']['output'];
  created: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type BlacklistNode = {
  __typename: 'BlacklistNode';
  items?: Maybe<Array<Maybe<BlacklistItemNode>>>;
  pageInfo?: Maybe<PageInfoNode>;
};

export type CardActionListNode = {
  __typename: 'CardActionListNode';
  actions: Array<Maybe<CardActionNode>>;
  pageInfo: PageInfoNode;
};

export type CardActionNode = {
  __typename: 'CardActionNode';
  address?: Maybe<Scalars['String']['output']>;
  amountToReceive: Scalars['String']['output'];
  balance?: Maybe<Scalars['String']['output']>;
  cardId?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  cardStatus?: Maybe<CardStatus>;
  cardholderName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currencyToReceive: Scalars['String']['output'];
  eosAccount: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  orderId?: Maybe<Scalars['String']['output']>;
  orderStatus?: Maybe<UpayOrderStatus>;
  phoneCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  status: ActionStatus;
  txid?: Maybe<Scalars['String']['output']>;
  type: ActionType;
};

export type CardFeeEntryNode = {
  __typename: 'CardFeeEntryNode';
  fixedFee: Scalars['String']['output'];
  issuerFixedFee: Scalars['String']['output'];
  issuerRate: Scalars['String']['output'];
  maximalAmount?: Maybe<Scalars['String']['output']>;
  minimalAmount: Scalars['String']['output'];
  rate: Scalars['String']['output'];
};

export type CardFeesNode = {
  __typename: 'CardFeesNode';
  emission: CardFeeEntryNode;
  replenishment: CardFeeEntryNode;
};

export type CardIsActiveError = {
  __typename: 'CardIsActiveError';
  errorMessage: Scalars['String']['output'];
};

export type CardIsInactiveError = {
  __typename: 'CardIsInactiveError';
  errorMessage: Scalars['String']['output'];
};

export type CardMaintenanceStatusNode = {
  __typename: 'CardMaintenanceStatusNode';
  actions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
};

export type CardNode = {
  __typename: 'CardNode';
  cardholderName: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  emittedAt: Scalars['DateTime']['output'];
  emitterName: Scalars['String']['output'];
  eosAccount: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type CardNotFoundError = {
  __typename: 'CardNotFoundError';
  errorMessage: Scalars['String']['output'];
};

export enum CardStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
  PendingActivation = 'PENDING_ACTIVATION',
  Redeemed = 'REDEEMED'
}

export type CardSystemStatusNode = {
  __typename: 'CardSystemStatusNode';
  maintenance?: Maybe<CardMaintenanceStatusNode>;
};

export type CardUpdateMaintenanceInput = {
  actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CardUpdateSystemStatusInput = {
  maintenance?: InputMaybe<CardUpdateMaintenanceInput>;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateUserOutput = CreateUserSuccess | UserExistsError;

export type CreateUserSuccess = {
  __typename: 'CreateUserSuccess';
  user?: Maybe<UserNode>;
};

export type CurrencyStatisticsNode = {
  __typename: 'CurrencyStatisticsNode';
  currencyName?: Maybe<Scalars['String']['output']>;
  day?: Maybe<PeriodStatisticsNode>;
  month?: Maybe<PeriodStatisticsNode>;
  week?: Maybe<PeriodStatisticsNode>;
};

export type DeleteFromBlacklistInput = {
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type DeleteFromBlacklistSuccess = {
  __typename: 'DeleteFromBlacklistSuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUserOutput = DeleteUserSuccess | UserNotFoundError;

export type DeleteUserSuccess = {
  __typename: 'DeleteUserSuccess';
  success: Scalars['Boolean']['output'];
};

export enum EmitterNameEnum {
  Physical_1 = 'PHYSICAL_1',
  Virtual_1 = 'VIRTUAL_1'
}

export type ExchangeAmountNode = {
  __typename: 'ExchangeAmountNode';
  amount: Scalars['String']['output'];
  blockchain?: Maybe<Scalars['String']['output']>;
  methodId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  storeId?: Maybe<Scalars['String']['output']>;
  txid?: Maybe<Scalars['String']['output']>;
};

export enum ExchangeDirectionEnum {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW'
}

export type ExchangeFilterInput = {
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<ExchangeDirectionEnum>;
  eosAccount?: InputMaybe<Scalars['String']['input']>;
  exchangePairId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inputCurrencyName?: InputMaybe<Scalars['String']['input']>;
  inputMethodId?: InputMaybe<Scalars['String']['input']>;
  outputCurrencyName?: InputMaybe<Scalars['String']['input']>;
  outputMethodId?: InputMaybe<Scalars['String']['input']>;
  pc4storeOrderId?: InputMaybe<Scalars['String']['input']>;
  pc4storeStatus?: InputMaybe<Scalars['String']['input']>;
  pc4storeTransferId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ExchangeStatusEnum>;
  type?: InputMaybe<ExchangeTypeEnum>;
};

export type ExchangeListNode = {
  __typename: 'ExchangeListNode';
  exchanges?: Maybe<Array<Maybe<ExchangeNode>>>;
  pageInfo?: Maybe<PageInfoNode>;
};

export type ExchangeNode = {
  __typename: 'ExchangeNode';
  created?: Maybe<Scalars['String']['output']>;
  exchangePairId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  input?: Maybe<ExchangeAmountNode>;
  memo?: Maybe<Scalars['String']['output']>;
  output?: Maybe<ExchangeAmountNode>;
  paymentLink?: Maybe<Scalars['String']['output']>;
  receiver?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ExchangeStatusEnum>;
  type?: Maybe<ExchangeTypeEnum>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type ExchangePairFilterInput = {
  direction?: InputMaybe<ExchangeDirectionEnum>;
  id?: InputMaybe<Scalars['String']['input']>;
  inputCurrencyName?: InputMaybe<Scalars['String']['input']>;
  inputMethodId?: InputMaybe<Scalars['String']['input']>;
  isAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  outputCurrencyName?: InputMaybe<Scalars['String']['input']>;
  outputMethodId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ExchangeTypeEnum>;
};

export type ExchangePairNode = {
  __typename: 'ExchangePairNode';
  createdAt?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inputCurrencyName: Scalars['String']['output'];
  inputFeeFix?: Maybe<Scalars['String']['output']>;
  inputFeePercentage?: Maybe<Scalars['String']['output']>;
  inputMethodId?: Maybe<Scalars['String']['output']>;
  inputMethodName?: Maybe<Scalars['String']['output']>;
  inputPrecision?: Maybe<Scalars['Int']['output']>;
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  maximalAmount?: Maybe<Scalars['String']['output']>;
  minimalAmount?: Maybe<Scalars['String']['output']>;
  outputCurrencyName: Scalars['String']['output'];
  outputFeeFix?: Maybe<Scalars['String']['output']>;
  outputFeePercentage?: Maybe<Scalars['String']['output']>;
  outputMethodId?: Maybe<Scalars['String']['output']>;
  outputMethodName?: Maybe<Scalars['String']['output']>;
  outputPrecision?: Maybe<Scalars['Int']['output']>;
  type: ExchangeTypeEnum;
};

export type ExchangePairUpdateInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ExchangeStatusEnum {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Frozen = 'FROZEN',
  MoneyReceived = 'MONEY_RECEIVED',
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  Sending = 'SENDING',
  Sent = 'SENT',
  Success = 'SUCCESS'
}

export enum ExchangeTypeEnum {
  CryptoCrypto = 'CRYPTO_CRYPTO',
  CryptoFiat = 'CRYPTO_FIAT',
  FiatCrypto = 'FIAT_CRYPTO'
}

export type InvalidCredentialsError = {
  __typename: 'InvalidCredentialsError';
  errorMessage: Scalars['String']['output'];
};

export type MaintenanceStatusDNode = {
  __typename: 'MaintenanceStatusDNode';
  description?: Maybe<Scalars['String']['output']>;
  endTimestamp?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
};

export type MutationSuccess = {
  __typename: 'MutationSuccess';
  success: Scalars['Boolean']['output'];
};

export type Mutations = {
  __typename: 'Mutations';
  activateCard?: Maybe<ActivateCardOutput>;
  addToBlacklist?: Maybe<AddToBlacklistSuccess>;
  cardUpdateSystemStatus?: Maybe<CardSystemStatusNode>;
  createUser?: Maybe<CreateUserOutput>;
  deleteFromBlacklist?: Maybe<DeleteFromBlacklistSuccess>;
  deleteUser?: Maybe<DeleteUserOutput>;
  refreshPassword?: Maybe<RefreshUserOutput>;
  sendCard?: Maybe<SendCardOutput>;
  signin?: Maybe<SigninOutput>;
  unfreezeExchange?: Maybe<UnfreezeExchangeSuccess>;
  updateExchangePairs?: Maybe<Array<Maybe<ExchangePairNode>>>;
  updateSystemStatus?: Maybe<SystemStatusNode>;
};


export type MutationsActivateCardArgs = {
  input: ActivateCardInput;
};


export type MutationsAddToBlacklistArgs = {
  request?: InputMaybe<AddToBlacklistInput>;
};


export type MutationsCardUpdateSystemStatusArgs = {
  request?: InputMaybe<CardUpdateSystemStatusInput>;
};


export type MutationsCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationsDeleteFromBlacklistArgs = {
  request?: InputMaybe<DeleteFromBlacklistInput>;
};


export type MutationsDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationsRefreshPasswordArgs = {
  id: Scalars['String']['input'];
};


export type MutationsSendCardArgs = {
  input: SendCardInput;
};


export type MutationsSigninArgs = {
  input: SigninInput;
};


export type MutationsUnfreezeExchangeArgs = {
  request?: InputMaybe<UnfreezeExchangeInput>;
};


export type MutationsUpdateExchangePairsArgs = {
  filters?: InputMaybe<ExchangePairFilterInput>;
  request?: InputMaybe<ExchangePairUpdateInput>;
};


export type MutationsUpdateSystemStatusArgs = {
  request?: InputMaybe<UpdateSystemStatusInput>;
};

export type PageInfoNode = {
  __typename: 'PageInfoNode';
  total?: Maybe<Scalars['Int']['output']>;
  totalFiltered?: Maybe<Scalars['Int']['output']>;
};

export type PeriodStatisticsNode = {
  __typename: 'PeriodStatisticsNode';
  deposit?: Maybe<Scalars['String']['output']>;
  withdraw?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename: 'Query';
  blacklist?: Maybe<BlacklistNode>;
  cardActions?: Maybe<CardActionListNode>;
  cardFees?: Maybe<CardFeesNode>;
  cardSystemStatus?: Maybe<CardSystemStatusNode>;
  exchange?: Maybe<ExchangeNode>;
  exchangePairs?: Maybe<Array<Maybe<ExchangePairNode>>>;
  exchanges?: Maybe<ExchangeListNode>;
  me?: Maybe<UserNode>;
  statistics?: Maybe<StatisticsNode>;
  systemStatus?: Maybe<SystemStatusNode>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};


export type QueryBlacklistArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCardActionsArgs = {
  filters: ActionFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCardFeesArgs = {
  emitterName: EmitterNameEnum;
};


export type QueryExchangeArgs = {
  id: Scalars['String']['input'];
};


export type QueryExchangePairsArgs = {
  filters?: InputMaybe<ExchangePairFilterInput>;
};


export type QueryExchangesArgs = {
  filters?: InputMaybe<ExchangeFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type RefreshPasswordSuccess = {
  __typename: 'RefreshPasswordSuccess';
  success: Scalars['Boolean']['output'];
};

export type RefreshUserOutput = RefreshPasswordSuccess | UserNotFoundError;

export type SendCardInput = {
  requestId: Scalars['String']['input'];
};

export type SendCardOutput = ActionNotFoundError | CardIsInactiveError | CardNotFoundError | MutationSuccess | UnderMaintenanceError | WrongActionStatusError | WrongEmitterError;

export type SigninInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SigninOutput = InvalidCredentialsError | SigninSuccess | UserDeactivatedError;

export type SigninSuccess = {
  __typename: 'SigninSuccess';
  me?: Maybe<UserNode>;
  token: Scalars['String']['output'];
};

export type StatisticsNode = {
  __typename: 'StatisticsNode';
  currencies?: Maybe<Array<Maybe<CurrencyStatisticsNode>>>;
  totalCount?: Maybe<TotalCountStatisticsNode>;
};

export type SystemStatusNode = {
  __typename: 'SystemStatusNode';
  maintenance?: Maybe<MaintenanceStatusDNode>;
};

export type TotalCountStatisticsNode = {
  __typename: 'TotalCountStatisticsNode';
  day?: Maybe<PeriodStatisticsNode>;
  month?: Maybe<PeriodStatisticsNode>;
  week?: Maybe<PeriodStatisticsNode>;
};

export type UnderMaintenanceError = {
  __typename: 'UnderMaintenanceError';
  errorMessage: Scalars['String']['output'];
};

export type UnfreezeExchangeInput = {
  exchangeId: Scalars['String']['input'];
};

export type UnfreezeExchangeSuccess = {
  __typename: 'UnfreezeExchangeSuccess';
  exchange?: Maybe<ExchangeNode>;
};

export enum UpayOrderStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type UpdateMaintenanceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSystemStatusInput = {
  maintenance?: InputMaybe<UpdateMaintenanceInput>;
};

export type UserDeactivatedError = {
  __typename: 'UserDeactivatedError';
  errorMessage: Scalars['String']['output'];
};

export type UserExistsError = {
  __typename: 'UserExistsError';
  errorMessage: Scalars['String']['output'];
};

export type UserNode = {
  __typename: 'UserNode';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type UserNotFoundError = {
  __typename: 'UserNotFoundError';
  errorMessage: Scalars['String']['output'];
};

export type WrongActionStatusError = {
  __typename: 'WrongActionStatusError';
  errorMessage: Scalars['String']['output'];
};

export type WrongEmitterError = {
  __typename: 'WrongEmitterError';
  errorMessage: Scalars['String']['output'];
};

export type CardFragment = { __typename: 'CardNode', id: string, emittedAt: any, cardholderName: string, eosAccount: string, currency: string, status: string, emitterName: string };

export type CardActionDataFragment = { __typename: 'CardActionNode', id: string, type: ActionType, status: ActionStatus, orderStatus?: UpayOrderStatus | null, cardStatus?: CardStatus | null, createdAt: any, eosAccount: string, amountToReceive: string, currencyToReceive: string, txid?: string | null, cardholderName: string, orderId?: string | null, cardId?: string | null, cardNumber?: string | null, balance?: string | null, fullName?: string | null, address?: string | null, phoneCode?: string | null, phoneNumber?: string | null };

export type ExchangeFragment = { __typename: 'ExchangeNode', id: string, type?: ExchangeTypeEnum | null, created?: string | null, updated?: string | null, receiver?: string | null, status?: ExchangeStatusEnum | null, sender?: string | null, memo?: string | null, output?: { __typename: 'ExchangeAmountNode', blockchain?: string | null, amount: string, name: string, txid?: string | null, methodId: string, storeId?: string | null } | null, input?: { __typename: 'ExchangeAmountNode', blockchain?: string | null, amount: string, name: string, txid?: string | null, methodId: string, storeId?: string | null } | null };

export type ExchangePairsFragment = { __typename: 'ExchangePairNode', id: string, type: ExchangeTypeEnum, createdAt?: string | null, isAvailable?: boolean | null, isEnabled?: boolean | null, outputCurrencyName: string, inputCurrencyName: string, inputMethodId?: string | null, outputMethodId?: string | null, inputMethodName?: string | null, outputMethodName?: string | null };

export type MeFragment = { __typename: 'UserNode', id: string, email: string, isActive: boolean, createdAt: any };

export type ActivateCardMutationVariables = Exact<{
  input: ActivateCardInput;
}>;


export type ActivateCardMutation = { __typename: 'Mutations', activateCard?: { __typename: 'ActionNotFoundError' } | { __typename: 'ActivateCardSuccess', card: { __typename: 'CardNode', id: string, emittedAt: any, cardholderName: string, eosAccount: string, currency: string, status: string, emitterName: string } } | { __typename: 'CardIsActiveError' } | { __typename: 'CardNotFoundError' } | { __typename: 'UnderMaintenanceError' } | { __typename: 'WrongActionStatusError' } | { __typename: 'WrongEmitterError' } | null };

export type AddToBlackListMutationVariables = Exact<{
  input?: InputMaybe<AddToBlacklistInput>;
}>;


export type AddToBlackListMutation = { __typename: 'Mutations', addToBlacklist?: { __typename: 'AddToBlacklistSuccess', items?: Array<{ __typename: 'BlacklistItemNode', account: string, created: string, id: string } | null> | null } | null };

export type CardMaintenanceMutationVariables = Exact<{
  request?: InputMaybe<CardUpdateSystemStatusInput>;
}>;


export type CardMaintenanceMutation = { __typename: 'Mutations', cardUpdateSystemStatus?: { __typename: 'CardSystemStatusNode' } | null };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename: 'Mutations', createUser?: { __typename: 'CreateUserSuccess', user?: { __typename: 'UserNode', isActive: boolean, name: string, id: string, email: string, createdAt: any } | null } | { __typename: 'UserExistsError', errorMessage: string } | null };

export type DeleteFromBlackListMutationVariables = Exact<{
  input?: InputMaybe<DeleteFromBlacklistInput>;
}>;


export type DeleteFromBlackListMutation = { __typename: 'Mutations', deleteFromBlacklist?: { __typename: 'DeleteFromBlacklistSuccess', success?: boolean | null } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename: 'Mutations', deleteUser?: { __typename: 'DeleteUserSuccess', success: boolean } | { __typename: 'UserNotFoundError', errorMessage: string } | null };

export type RefreshPasswordMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RefreshPasswordMutation = { __typename: 'Mutations', refreshPassword?: { __typename: 'RefreshPasswordSuccess', success: boolean } | { __typename: 'UserNotFoundError', errorMessage: string } | null };

export type SendCardMutationVariables = Exact<{
  input: SendCardInput;
}>;


export type SendCardMutation = { __typename: 'Mutations', sendCard?: { __typename: 'ActionNotFoundError' } | { __typename: 'CardIsInactiveError' } | { __typename: 'CardNotFoundError' } | { __typename: 'MutationSuccess' } | { __typename: 'UnderMaintenanceError' } | { __typename: 'WrongActionStatusError' } | { __typename: 'WrongEmitterError' } | null };

export type SigninMutationVariables = Exact<{
  input: SigninInput;
}>;


export type SigninMutation = { __typename: 'Mutations', signin?: { __typename: 'InvalidCredentialsError', errorMessage: string } | { __typename: 'SigninSuccess', token: string, me?: { __typename: 'UserNode', id: string, email: string, isActive: boolean, createdAt: any } | null } | { __typename: 'UserDeactivatedError', errorMessage: string } | null };

export type UnfreezeExchangeMutationVariables = Exact<{
  request?: InputMaybe<UnfreezeExchangeInput>;
}>;


export type UnfreezeExchangeMutation = { __typename: 'Mutations', unfreezeExchange?: { __typename: 'UnfreezeExchangeSuccess', exchange?: { __typename: 'ExchangeNode', id: string, type?: ExchangeTypeEnum | null, created?: string | null, updated?: string | null, receiver?: string | null, status?: ExchangeStatusEnum | null, sender?: string | null, memo?: string | null, output?: { __typename: 'ExchangeAmountNode', blockchain?: string | null, amount: string, name: string, txid?: string | null, methodId: string, storeId?: string | null } | null, input?: { __typename: 'ExchangeAmountNode', blockchain?: string | null, amount: string, name: string, txid?: string | null, methodId: string, storeId?: string | null } | null } | null } | null };

export type UpdateExchangePairsMutationVariables = Exact<{
  filters?: InputMaybe<ExchangePairFilterInput>;
  request?: InputMaybe<ExchangePairUpdateInput>;
}>;


export type UpdateExchangePairsMutation = { __typename: 'Mutations', updateExchangePairs?: Array<{ __typename: 'ExchangePairNode', id: string, type: ExchangeTypeEnum, createdAt?: string | null, isAvailable?: boolean | null, isEnabled?: boolean | null, outputCurrencyName: string, inputCurrencyName: string, inputMethodId?: string | null, outputMethodId?: string | null, inputMethodName?: string | null, outputMethodName?: string | null } | null> | null };

export type UpdateSystemStatusMutationVariables = Exact<{
  request: UpdateSystemStatusInput;
}>;


export type UpdateSystemStatusMutation = { __typename: 'Mutations', updateSystemStatus?: { __typename: 'SystemStatusNode', maintenance?: { __typename: 'MaintenanceStatusDNode', isActive?: boolean | null, description?: string | null, endTimestamp?: string | null } | null } | null };

export type BlackListUsersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type BlackListUsersQuery = { __typename: 'Query', blacklist?: { __typename: 'BlacklistNode', pageInfo?: { __typename: 'PageInfoNode', total?: number | null, totalFiltered?: number | null } | null, items?: Array<{ __typename: 'BlacklistItemNode', account: string, created: string, id: string } | null> | null } | null };

export type CardActionsQueryVariables = Exact<{
  filters: ActionFilterInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
}>;


export type CardActionsQuery = { __typename: 'Query', cardActions?: { __typename: 'CardActionListNode', actions: Array<{ __typename: 'CardActionNode', id: string, type: ActionType, status: ActionStatus, orderStatus?: UpayOrderStatus | null, cardStatus?: CardStatus | null, createdAt: any, eosAccount: string, amountToReceive: string, currencyToReceive: string, txid?: string | null, cardholderName: string, orderId?: string | null, cardId?: string | null, cardNumber?: string | null, balance?: string | null, fullName?: string | null, address?: string | null, phoneCode?: string | null, phoneNumber?: string | null } | null>, pageInfo: { __typename: 'PageInfoNode', total?: number | null, totalFiltered?: number | null } } | null };

export type CardSystemStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type CardSystemStatusQuery = { __typename: 'Query', cardSystemStatus?: { __typename: 'CardSystemStatusNode', maintenance?: { __typename: 'CardMaintenanceStatusNode', isActive?: boolean | null, description?: string | null, endTime?: string | null, actions?: Array<string | null> | null } | null } | null };

export type CurrenciesStatisticQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrenciesStatisticQuery = { __typename: 'Query', statistics?: { __typename: 'StatisticsNode', currencies?: Array<{ __typename: 'CurrencyStatisticsNode', currencyName?: string | null, day?: { __typename: 'PeriodStatisticsNode', deposit?: string | null, withdraw?: string | null } | null, month?: { __typename: 'PeriodStatisticsNode', deposit?: string | null, withdraw?: string | null } | null, week?: { __typename: 'PeriodStatisticsNode', deposit?: string | null, withdraw?: string | null } | null } | null> | null } | null };

export type ExchangeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ExchangeQuery = { __typename: 'Query', exchange?: { __typename: 'ExchangeNode', id: string, type?: ExchangeTypeEnum | null, created?: string | null, updated?: string | null, receiver?: string | null, status?: ExchangeStatusEnum | null, sender?: string | null, memo?: string | null, output?: { __typename: 'ExchangeAmountNode', blockchain?: string | null, amount: string, name: string, txid?: string | null, methodId: string, storeId?: string | null } | null, input?: { __typename: 'ExchangeAmountNode', blockchain?: string | null, amount: string, name: string, txid?: string | null, methodId: string, storeId?: string | null } | null } | null };

export type ExchangesQueryVariables = Exact<{
  filters?: InputMaybe<ExchangeFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExchangesQuery = { __typename: 'Query', exchanges?: { __typename: 'ExchangeListNode', exchanges?: Array<{ __typename: 'ExchangeNode', id: string, type?: ExchangeTypeEnum | null, created?: string | null, updated?: string | null, receiver?: string | null, status?: ExchangeStatusEnum | null, sender?: string | null, memo?: string | null, output?: { __typename: 'ExchangeAmountNode', blockchain?: string | null, amount: string, name: string, txid?: string | null, methodId: string, storeId?: string | null } | null, input?: { __typename: 'ExchangeAmountNode', blockchain?: string | null, amount: string, name: string, txid?: string | null, methodId: string, storeId?: string | null } | null } | null> | null, pageInfo?: { __typename: 'PageInfoNode', total?: number | null, totalFiltered?: number | null } | null } | null };

export type ExchangePairsQueryVariables = Exact<{
  filters?: InputMaybe<ExchangePairFilterInput>;
}>;


export type ExchangePairsQuery = { __typename: 'Query', exchangePairs?: Array<{ __typename: 'ExchangePairNode', id: string, type: ExchangeTypeEnum, createdAt?: string | null, isAvailable?: boolean | null, isEnabled?: boolean | null, outputCurrencyName: string, inputCurrencyName: string, inputMethodId?: string | null, outputMethodId?: string | null, inputMethodName?: string | null, outputMethodName?: string | null } | null> | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename: 'Query', me?: { __typename: 'UserNode', id: string, email: string, isActive: boolean, createdAt: any } | null };

export type SystemStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemStatusQuery = { __typename: 'Query', systemStatus?: { __typename: 'SystemStatusNode', maintenance?: { __typename: 'MaintenanceStatusDNode', isActive?: boolean | null, description?: string | null, endTimestamp?: string | null } | null } | null };

export type TotalCountStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalCountStatisticsQuery = { __typename: 'Query', statistics?: { __typename: 'StatisticsNode', totalCount?: { __typename: 'TotalCountStatisticsNode', day?: { __typename: 'PeriodStatisticsNode', deposit?: string | null, withdraw?: string | null } | null, week?: { __typename: 'PeriodStatisticsNode', deposit?: string | null, withdraw?: string | null } | null, month?: { __typename: 'PeriodStatisticsNode', deposit?: string | null, withdraw?: string | null } | null } | null } | null };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename: 'Query', users?: Array<{ __typename: 'UserNode', createdAt: any, email: string, id: string, isActive: boolean, name: string } | null> | null };

export const CardFragmentDoc = gql`
    fragment Card on CardNode {
  id
  emittedAt
  cardholderName
  eosAccount
  currency
  status
  emitterName
}
    `;
export const CardActionDataFragmentDoc = gql`
    fragment CardActionData on CardActionNode {
  id
  type
  status
  orderStatus
  cardStatus
  createdAt
  eosAccount
  amountToReceive
  currencyToReceive
  txid
  cardholderName
  orderId
  cardId
  cardNumber
  balance
  fullName
  address
  phoneCode
  phoneNumber
}
    `;
export const ExchangeFragmentDoc = gql`
    fragment Exchange on ExchangeNode {
  id
  type
  created
  updated
  receiver
  status
  sender
  memo
  output {
    blockchain
    amount
    name
    txid
    methodId
    storeId
  }
  input {
    blockchain
    amount
    name
    txid
    methodId
    storeId
  }
}
    `;
export const ExchangePairsFragmentDoc = gql`
    fragment ExchangePairs on ExchangePairNode {
  id
  type
  createdAt
  isAvailable
  isEnabled
  outputCurrencyName
  inputCurrencyName
  inputMethodId
  outputMethodId
  inputMethodName
  outputMethodName
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on UserNode {
  id
  email
  isActive
  createdAt
}
    `;
export const ActivateCardDocument = gql`
    mutation ActivateCard($input: ActivateCardInput!) {
  activateCard(input: $input) {
    ... on ActivateCardSuccess {
      card {
        ...Card
      }
    }
  }
}
    ${CardFragmentDoc}`;
export type ActivateCardMutationFn = Apollo.MutationFunction<ActivateCardMutation, ActivateCardMutationVariables>;

/**
 * __useActivateCardMutation__
 *
 * To run a mutation, you first call `useActivateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCardMutation, { data, loading, error }] = useActivateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCardMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCardMutation, ActivateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateCardMutation, ActivateCardMutationVariables>(ActivateCardDocument, options);
      }
export type ActivateCardMutationHookResult = ReturnType<typeof useActivateCardMutation>;
export type ActivateCardMutationResult = Apollo.MutationResult<ActivateCardMutation>;
export type ActivateCardMutationOptions = Apollo.BaseMutationOptions<ActivateCardMutation, ActivateCardMutationVariables>;
export const AddToBlackListDocument = gql`
    mutation AddToBlackList($input: AddToBlacklistInput) {
  addToBlacklist(request: $input) {
    ... on AddToBlacklistSuccess {
      items {
        account
        created
        id
      }
    }
  }
}
    `;
export type AddToBlackListMutationFn = Apollo.MutationFunction<AddToBlackListMutation, AddToBlackListMutationVariables>;

/**
 * __useAddToBlackListMutation__
 *
 * To run a mutation, you first call `useAddToBlackListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToBlackListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToBlackListMutation, { data, loading, error }] = useAddToBlackListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToBlackListMutation(baseOptions?: Apollo.MutationHookOptions<AddToBlackListMutation, AddToBlackListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToBlackListMutation, AddToBlackListMutationVariables>(AddToBlackListDocument, options);
      }
export type AddToBlackListMutationHookResult = ReturnType<typeof useAddToBlackListMutation>;
export type AddToBlackListMutationResult = Apollo.MutationResult<AddToBlackListMutation>;
export type AddToBlackListMutationOptions = Apollo.BaseMutationOptions<AddToBlackListMutation, AddToBlackListMutationVariables>;
export const CardMaintenanceDocument = gql`
    mutation CardMaintenance($request: CardUpdateSystemStatusInput) {
  cardUpdateSystemStatus(request: $request) {
    __typename
  }
}
    `;
export type CardMaintenanceMutationFn = Apollo.MutationFunction<CardMaintenanceMutation, CardMaintenanceMutationVariables>;

/**
 * __useCardMaintenanceMutation__
 *
 * To run a mutation, you first call `useCardMaintenanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCardMaintenanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cardMaintenanceMutation, { data, loading, error }] = useCardMaintenanceMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCardMaintenanceMutation(baseOptions?: Apollo.MutationHookOptions<CardMaintenanceMutation, CardMaintenanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CardMaintenanceMutation, CardMaintenanceMutationVariables>(CardMaintenanceDocument, options);
      }
export type CardMaintenanceMutationHookResult = ReturnType<typeof useCardMaintenanceMutation>;
export type CardMaintenanceMutationResult = Apollo.MutationResult<CardMaintenanceMutation>;
export type CardMaintenanceMutationOptions = Apollo.BaseMutationOptions<CardMaintenanceMutation, CardMaintenanceMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ... on CreateUserSuccess {
      user {
        isActive
        name
        id
        email
        createdAt
      }
    }
    ... on UserExistsError {
      errorMessage
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteFromBlackListDocument = gql`
    mutation DeleteFromBlackList($input: DeleteFromBlacklistInput) {
  deleteFromBlacklist(request: $input) {
    ... on DeleteFromBlacklistSuccess {
      success
    }
  }
}
    `;
export type DeleteFromBlackListMutationFn = Apollo.MutationFunction<DeleteFromBlackListMutation, DeleteFromBlackListMutationVariables>;

/**
 * __useDeleteFromBlackListMutation__
 *
 * To run a mutation, you first call `useDeleteFromBlackListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFromBlackListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFromBlackListMutation, { data, loading, error }] = useDeleteFromBlackListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFromBlackListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFromBlackListMutation, DeleteFromBlackListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFromBlackListMutation, DeleteFromBlackListMutationVariables>(DeleteFromBlackListDocument, options);
      }
export type DeleteFromBlackListMutationHookResult = ReturnType<typeof useDeleteFromBlackListMutation>;
export type DeleteFromBlackListMutationResult = Apollo.MutationResult<DeleteFromBlackListMutation>;
export type DeleteFromBlackListMutationOptions = Apollo.BaseMutationOptions<DeleteFromBlackListMutation, DeleteFromBlackListMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id) {
    ... on DeleteUserSuccess {
      success
      __typename
    }
    ... on UserNotFoundError {
      errorMessage
      __typename
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const RefreshPasswordDocument = gql`
    mutation RefreshPassword($id: String!) {
  refreshPassword(id: $id) {
    ... on RefreshPasswordSuccess {
      __typename
      success
    }
    ... on UserNotFoundError {
      __typename
      errorMessage
    }
  }
}
    `;
export type RefreshPasswordMutationFn = Apollo.MutationFunction<RefreshPasswordMutation, RefreshPasswordMutationVariables>;

/**
 * __useRefreshPasswordMutation__
 *
 * To run a mutation, you first call `useRefreshPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshPasswordMutation, { data, loading, error }] = useRefreshPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefreshPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RefreshPasswordMutation, RefreshPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshPasswordMutation, RefreshPasswordMutationVariables>(RefreshPasswordDocument, options);
      }
export type RefreshPasswordMutationHookResult = ReturnType<typeof useRefreshPasswordMutation>;
export type RefreshPasswordMutationResult = Apollo.MutationResult<RefreshPasswordMutation>;
export type RefreshPasswordMutationOptions = Apollo.BaseMutationOptions<RefreshPasswordMutation, RefreshPasswordMutationVariables>;
export const SendCardDocument = gql`
    mutation SendCard($input: SendCardInput!) {
  sendCard(input: $input) {
    __typename
  }
}
    `;
export type SendCardMutationFn = Apollo.MutationFunction<SendCardMutation, SendCardMutationVariables>;

/**
 * __useSendCardMutation__
 *
 * To run a mutation, you first call `useSendCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCardMutation, { data, loading, error }] = useSendCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCardMutation(baseOptions?: Apollo.MutationHookOptions<SendCardMutation, SendCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCardMutation, SendCardMutationVariables>(SendCardDocument, options);
      }
export type SendCardMutationHookResult = ReturnType<typeof useSendCardMutation>;
export type SendCardMutationResult = Apollo.MutationResult<SendCardMutation>;
export type SendCardMutationOptions = Apollo.BaseMutationOptions<SendCardMutation, SendCardMutationVariables>;
export const SigninDocument = gql`
    mutation Signin($input: SigninInput!) {
  signin(input: $input) {
    ... on SigninSuccess {
      token
      me {
        ...Me
      }
    }
    ... on InvalidCredentialsError {
      errorMessage
    }
    ... on UserDeactivatedError {
      errorMessage
    }
  }
}
    ${MeFragmentDoc}`;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const UnfreezeExchangeDocument = gql`
    mutation UnfreezeExchange($request: UnfreezeExchangeInput) {
  unfreezeExchange(request: $request) {
    ... on UnfreezeExchangeSuccess {
      exchange {
        ...Exchange
      }
    }
  }
}
    ${ExchangeFragmentDoc}`;
export type UnfreezeExchangeMutationFn = Apollo.MutationFunction<UnfreezeExchangeMutation, UnfreezeExchangeMutationVariables>;

/**
 * __useUnfreezeExchangeMutation__
 *
 * To run a mutation, you first call `useUnfreezeExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfreezeExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfreezeExchangeMutation, { data, loading, error }] = useUnfreezeExchangeMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUnfreezeExchangeMutation(baseOptions?: Apollo.MutationHookOptions<UnfreezeExchangeMutation, UnfreezeExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfreezeExchangeMutation, UnfreezeExchangeMutationVariables>(UnfreezeExchangeDocument, options);
      }
export type UnfreezeExchangeMutationHookResult = ReturnType<typeof useUnfreezeExchangeMutation>;
export type UnfreezeExchangeMutationResult = Apollo.MutationResult<UnfreezeExchangeMutation>;
export type UnfreezeExchangeMutationOptions = Apollo.BaseMutationOptions<UnfreezeExchangeMutation, UnfreezeExchangeMutationVariables>;
export const UpdateExchangePairsDocument = gql`
    mutation UpdateExchangePairs($filters: ExchangePairFilterInput, $request: ExchangePairUpdateInput) {
  updateExchangePairs(filters: $filters, request: $request) {
    ... on ExchangePairNode {
      ...ExchangePairs
    }
  }
}
    ${ExchangePairsFragmentDoc}`;
export type UpdateExchangePairsMutationFn = Apollo.MutationFunction<UpdateExchangePairsMutation, UpdateExchangePairsMutationVariables>;

/**
 * __useUpdateExchangePairsMutation__
 *
 * To run a mutation, you first call `useUpdateExchangePairsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExchangePairsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExchangePairsMutation, { data, loading, error }] = useUpdateExchangePairsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateExchangePairsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExchangePairsMutation, UpdateExchangePairsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExchangePairsMutation, UpdateExchangePairsMutationVariables>(UpdateExchangePairsDocument, options);
      }
export type UpdateExchangePairsMutationHookResult = ReturnType<typeof useUpdateExchangePairsMutation>;
export type UpdateExchangePairsMutationResult = Apollo.MutationResult<UpdateExchangePairsMutation>;
export type UpdateExchangePairsMutationOptions = Apollo.BaseMutationOptions<UpdateExchangePairsMutation, UpdateExchangePairsMutationVariables>;
export const UpdateSystemStatusDocument = gql`
    mutation UpdateSystemStatus($request: UpdateSystemStatusInput!) {
  updateSystemStatus(request: $request) {
    maintenance {
      isActive
      description
      endTimestamp
    }
  }
}
    `;
export type UpdateSystemStatusMutationFn = Apollo.MutationFunction<UpdateSystemStatusMutation, UpdateSystemStatusMutationVariables>;

/**
 * __useUpdateSystemStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSystemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemStatusMutation, { data, loading, error }] = useUpdateSystemStatusMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateSystemStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSystemStatusMutation, UpdateSystemStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSystemStatusMutation, UpdateSystemStatusMutationVariables>(UpdateSystemStatusDocument, options);
      }
export type UpdateSystemStatusMutationHookResult = ReturnType<typeof useUpdateSystemStatusMutation>;
export type UpdateSystemStatusMutationResult = Apollo.MutationResult<UpdateSystemStatusMutation>;
export type UpdateSystemStatusMutationOptions = Apollo.BaseMutationOptions<UpdateSystemStatusMutation, UpdateSystemStatusMutationVariables>;
export const BlackListUsersDocument = gql`
    query BlackListUsers($skip: Int, $limit: Int) {
  blacklist(skip: $skip, limit: $limit) {
    pageInfo {
      total
      totalFiltered
    }
    items {
      account
      created
      id
    }
  }
}
    `;

/**
 * __useBlackListUsersQuery__
 *
 * To run a query within a React component, call `useBlackListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlackListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlackListUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBlackListUsersQuery(baseOptions?: Apollo.QueryHookOptions<BlackListUsersQuery, BlackListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlackListUsersQuery, BlackListUsersQueryVariables>(BlackListUsersDocument, options);
      }
export function useBlackListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlackListUsersQuery, BlackListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlackListUsersQuery, BlackListUsersQueryVariables>(BlackListUsersDocument, options);
        }
export type BlackListUsersQueryHookResult = ReturnType<typeof useBlackListUsersQuery>;
export type BlackListUsersLazyQueryHookResult = ReturnType<typeof useBlackListUsersLazyQuery>;
export type BlackListUsersQueryResult = Apollo.QueryResult<BlackListUsersQuery, BlackListUsersQueryVariables>;
export const CardActionsDocument = gql`
    query CardActions($filters: ActionFilterInput!, $skip: Int, $limit: Int, $sort: String, $order: String) {
  cardActions(
    filters: $filters
    limit: $limit
    skip: $skip
    order: $order
    sort: $sort
  ) {
    actions {
      ...CardActionData
    }
    pageInfo {
      total
      totalFiltered
    }
  }
}
    ${CardActionDataFragmentDoc}`;

/**
 * __useCardActionsQuery__
 *
 * To run a query within a React component, call `useCardActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardActionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCardActionsQuery(baseOptions: Apollo.QueryHookOptions<CardActionsQuery, CardActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardActionsQuery, CardActionsQueryVariables>(CardActionsDocument, options);
      }
export function useCardActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardActionsQuery, CardActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardActionsQuery, CardActionsQueryVariables>(CardActionsDocument, options);
        }
export type CardActionsQueryHookResult = ReturnType<typeof useCardActionsQuery>;
export type CardActionsLazyQueryHookResult = ReturnType<typeof useCardActionsLazyQuery>;
export type CardActionsQueryResult = Apollo.QueryResult<CardActionsQuery, CardActionsQueryVariables>;
export const CardSystemStatusDocument = gql`
    query CardSystemStatus {
  cardSystemStatus {
    maintenance {
      isActive
      description
      endTime
      actions
    }
  }
}
    `;

/**
 * __useCardSystemStatusQuery__
 *
 * To run a query within a React component, call `useCardSystemStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardSystemStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardSystemStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCardSystemStatusQuery(baseOptions?: Apollo.QueryHookOptions<CardSystemStatusQuery, CardSystemStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardSystemStatusQuery, CardSystemStatusQueryVariables>(CardSystemStatusDocument, options);
      }
export function useCardSystemStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardSystemStatusQuery, CardSystemStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardSystemStatusQuery, CardSystemStatusQueryVariables>(CardSystemStatusDocument, options);
        }
export type CardSystemStatusQueryHookResult = ReturnType<typeof useCardSystemStatusQuery>;
export type CardSystemStatusLazyQueryHookResult = ReturnType<typeof useCardSystemStatusLazyQuery>;
export type CardSystemStatusQueryResult = Apollo.QueryResult<CardSystemStatusQuery, CardSystemStatusQueryVariables>;
export const CurrenciesStatisticDocument = gql`
    query CurrenciesStatistic {
  statistics {
    currencies {
      day {
        deposit
        withdraw
      }
      month {
        deposit
        withdraw
      }
      week {
        deposit
        withdraw
      }
      currencyName
    }
  }
}
    `;

/**
 * __useCurrenciesStatisticQuery__
 *
 * To run a query within a React component, call `useCurrenciesStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesStatisticQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesStatisticQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesStatisticQuery, CurrenciesStatisticQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrenciesStatisticQuery, CurrenciesStatisticQueryVariables>(CurrenciesStatisticDocument, options);
      }
export function useCurrenciesStatisticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesStatisticQuery, CurrenciesStatisticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrenciesStatisticQuery, CurrenciesStatisticQueryVariables>(CurrenciesStatisticDocument, options);
        }
export type CurrenciesStatisticQueryHookResult = ReturnType<typeof useCurrenciesStatisticQuery>;
export type CurrenciesStatisticLazyQueryHookResult = ReturnType<typeof useCurrenciesStatisticLazyQuery>;
export type CurrenciesStatisticQueryResult = Apollo.QueryResult<CurrenciesStatisticQuery, CurrenciesStatisticQueryVariables>;
export const ExchangeDocument = gql`
    query Exchange($id: String!) {
  exchange(id: $id) {
    ...Exchange
  }
}
    ${ExchangeFragmentDoc}`;

/**
 * __useExchangeQuery__
 *
 * To run a query within a React component, call `useExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExchangeQuery(baseOptions: Apollo.QueryHookOptions<ExchangeQuery, ExchangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExchangeQuery, ExchangeQueryVariables>(ExchangeDocument, options);
      }
export function useExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExchangeQuery, ExchangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExchangeQuery, ExchangeQueryVariables>(ExchangeDocument, options);
        }
export type ExchangeQueryHookResult = ReturnType<typeof useExchangeQuery>;
export type ExchangeLazyQueryHookResult = ReturnType<typeof useExchangeLazyQuery>;
export type ExchangeQueryResult = Apollo.QueryResult<ExchangeQuery, ExchangeQueryVariables>;
export const ExchangesDocument = gql`
    query Exchanges($filters: ExchangeFilterInput, $skip: Int, $limit: Int, $sort: String, $order: String) {
  exchanges(
    filters: $filters
    limit: $limit
    skip: $skip
    order: $order
    sort: $sort
  ) {
    exchanges {
      ...Exchange
    }
    pageInfo {
      total
      totalFiltered
    }
  }
}
    ${ExchangeFragmentDoc}`;

/**
 * __useExchangesQuery__
 *
 * To run a query within a React component, call `useExchangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExchangesQuery(baseOptions?: Apollo.QueryHookOptions<ExchangesQuery, ExchangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExchangesQuery, ExchangesQueryVariables>(ExchangesDocument, options);
      }
export function useExchangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExchangesQuery, ExchangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExchangesQuery, ExchangesQueryVariables>(ExchangesDocument, options);
        }
export type ExchangesQueryHookResult = ReturnType<typeof useExchangesQuery>;
export type ExchangesLazyQueryHookResult = ReturnType<typeof useExchangesLazyQuery>;
export type ExchangesQueryResult = Apollo.QueryResult<ExchangesQuery, ExchangesQueryVariables>;
export const ExchangePairsDocument = gql`
    query ExchangePairs($filters: ExchangePairFilterInput) {
  exchangePairs(filters: $filters) {
    ... on ExchangePairNode {
      ...ExchangePairs
    }
  }
}
    ${ExchangePairsFragmentDoc}`;

/**
 * __useExchangePairsQuery__
 *
 * To run a query within a React component, call `useExchangePairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangePairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangePairsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExchangePairsQuery(baseOptions?: Apollo.QueryHookOptions<ExchangePairsQuery, ExchangePairsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExchangePairsQuery, ExchangePairsQueryVariables>(ExchangePairsDocument, options);
      }
export function useExchangePairsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExchangePairsQuery, ExchangePairsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExchangePairsQuery, ExchangePairsQueryVariables>(ExchangePairsDocument, options);
        }
export type ExchangePairsQueryHookResult = ReturnType<typeof useExchangePairsQuery>;
export type ExchangePairsLazyQueryHookResult = ReturnType<typeof useExchangePairsLazyQuery>;
export type ExchangePairsQueryResult = Apollo.QueryResult<ExchangePairsQuery, ExchangePairsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SystemStatusDocument = gql`
    query SystemStatus {
  systemStatus {
    maintenance {
      isActive
      description
      endTimestamp
    }
  }
}
    `;

/**
 * __useSystemStatusQuery__
 *
 * To run a query within a React component, call `useSystemStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemStatusQuery(baseOptions?: Apollo.QueryHookOptions<SystemStatusQuery, SystemStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemStatusQuery, SystemStatusQueryVariables>(SystemStatusDocument, options);
      }
export function useSystemStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemStatusQuery, SystemStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemStatusQuery, SystemStatusQueryVariables>(SystemStatusDocument, options);
        }
export type SystemStatusQueryHookResult = ReturnType<typeof useSystemStatusQuery>;
export type SystemStatusLazyQueryHookResult = ReturnType<typeof useSystemStatusLazyQuery>;
export type SystemStatusQueryResult = Apollo.QueryResult<SystemStatusQuery, SystemStatusQueryVariables>;
export const TotalCountStatisticsDocument = gql`
    query TotalCountStatistics {
  statistics {
    totalCount {
      day {
        deposit
        withdraw
      }
      week {
        deposit
        withdraw
      }
      month {
        deposit
        withdraw
      }
    }
  }
}
    `;

/**
 * __useTotalCountStatisticsQuery__
 *
 * To run a query within a React component, call `useTotalCountStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalCountStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalCountStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalCountStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<TotalCountStatisticsQuery, TotalCountStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalCountStatisticsQuery, TotalCountStatisticsQueryVariables>(TotalCountStatisticsDocument, options);
      }
export function useTotalCountStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalCountStatisticsQuery, TotalCountStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalCountStatisticsQuery, TotalCountStatisticsQueryVariables>(TotalCountStatisticsDocument, options);
        }
export type TotalCountStatisticsQueryHookResult = ReturnType<typeof useTotalCountStatisticsQuery>;
export type TotalCountStatisticsLazyQueryHookResult = ReturnType<typeof useTotalCountStatisticsLazyQuery>;
export type TotalCountStatisticsQueryResult = Apollo.QueryResult<TotalCountStatisticsQuery, TotalCountStatisticsQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    createdAt
    email
    id
    isActive
    name
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActivateCardOutput": [
      "ActionNotFoundError",
      "ActivateCardSuccess",
      "CardIsActiveError",
      "CardNotFoundError",
      "UnderMaintenanceError",
      "WrongActionStatusError",
      "WrongEmitterError"
    ],
    "CreateUserOutput": [
      "CreateUserSuccess",
      "UserExistsError"
    ],
    "DeleteUserOutput": [
      "DeleteUserSuccess",
      "UserNotFoundError"
    ],
    "RefreshUserOutput": [
      "RefreshPasswordSuccess",
      "UserNotFoundError"
    ],
    "SendCardOutput": [
      "ActionNotFoundError",
      "CardIsInactiveError",
      "CardNotFoundError",
      "MutationSuccess",
      "UnderMaintenanceError",
      "WrongActionStatusError",
      "WrongEmitterError"
    ],
    "SigninOutput": [
      "InvalidCredentialsError",
      "SigninSuccess",
      "UserDeactivatedError"
    ]
  }
};
      export default result;
    