export default {
  title: 'Карты',
  tabApplications: 'Заявки на выпуск',
  tabIssued: 'Выпущенные карты',
  tableHeader: {
    created: 'Дата заявки',
    account: 'Аккаунт',
    phone: 'Номер телефона',
    name: 'Имя на карте',
    address: 'Адрес доставки',
    cardNumberAndStatus: 'Номер карты и Статус'
  },
  button: {
    release: 'Выпустить',
    complete: 'Завершить выпуск'
  },
  modal: {
    title: 'Выпуск карты',
    titleConfirm: 'Подтвердить выпуск',
    titleFinal: 'Подтвеждение',
    descriptionFinal: 'Я подтверждаю что карта напечатана и отправлена',
    button: 'Подтвердить',
    fieldAccount: 'EOS Аккаунт',
    fieldName: 'Имя на карте',
    fieldPanHint: 'Считайте магнитную полосу карты',
    fieldCvvHint: 'Введите CVV с карты',
    successTitle: 'Карта успешно активирована',
    successDescription: 'Вы можете приступать к печати карты',
    successButton: 'Готово',
    errorTitle: 'При активации произошла ошибка',
    errorDescription: 'Проверьте корректность введенных данных. Если все данные верны, повторите попытку еще раз. В случае повторной ошибки обратитесь к администратору',
    errorButton: 'Попробовать еще раз'
  }
};