import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from 'helpers/Auth/Auth';
import Preloader from 'ui/Preloader/Preloader';
import Layout from 'components/Layout/Layout';
import StartTour from 'components/StartTour/StartTour';
import NotFoundPage from './NotFoundPage';
import UnderConstructionPage from './UnderConstructionPage';
import LoginPage from './LoginPage';

const AdminListPage = React.lazy(() => import('./AdminListPage'));
const DashboardPage = React.lazy(() => import('./DashboardPage'));
const TransactionsPage = React.lazy(() => import('./TransactionsPage'));
const CardsPage = React.lazy(() => import('./CardsPage'));
const BlackListPage = React.lazy(() => import('./BlackListPage'));

export const URLS_MAP = {
    root: '/',
    login: '/login',
    signup: '/signup',
    recover: '/recover',
    dashboard: '/dashboard',
    transactions: '/transactions',
    cards: '/cards',
    blocked: '/blocked',
    list: '/list',
    notFound: '*'
};

function LoadProgress() {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0
            }}
        >
            <Preloader variant="linear" />
        </div>
    );
}

export default function MainRoutes() {
    return (
        <Routes>
            <Route path={URLS_MAP.login} element={<LoginPage />} />
            <Route element={<RequireAuth />}>
                <Route element={<Layout />}>
                    <Route path={URLS_MAP.root} element={<StartTour />} />
                    <Route
                        path={URLS_MAP.dashboard}
                        element={
                            <React.Suspense fallback={<LoadProgress />}>
                                <DashboardPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={URLS_MAP.transactions}
                        element={
                            <React.Suspense fallback={<LoadProgress />}>
                                <TransactionsPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={URLS_MAP.cards}
                        element={
                            <React.Suspense fallback={<LoadProgress />}>
                                <CardsPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={URLS_MAP.blocked}
                        element={
                            <React.Suspense fallback={<LoadProgress />}>
                                <BlackListPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={URLS_MAP.list}
                        element={
                            <React.Suspense fallback={<LoadProgress />}>
                                <AdminListPage />
                            </React.Suspense>
                        }
                    />
                    <Route path="*" element={<UnderConstructionPage />} />
                </Route>
            </Route>
            <Route path={URLS_MAP.notFound} element={<NotFoundPage />} />
        </Routes>
    );
}
