export default {
  title: 'Cards',
  tabApplications: 'Applications for release',
  tabIssued: 'Issued cards',
  tableHeader: {
    created: 'Application date',
    account: 'Account',
    phone: 'Phone number',
    name: 'Name on the card',
    address: 'Delivery address',
    cardNumberAndStatus: 'Card Number and Status'
  },
  button: {
    release: 'Release',
    complete: 'Complete'
  },
  modal: {
    title: 'Card issue',
    titleConfirm: 'Confirm release',
    titleFinal: 'Confirm',
    descriptionFinal: 'I confirm that the card has been printed and sent.',
    button: 'Confirm',
    fieldAccount: 'EOS Account',
    fieldName: 'Name on the card',
    fieldPanHint: 'Read the magnetic strip of the card',
    fieldCvvHint: 'Enter the CVV from the card',
    successTitle: 'The card has been successfully activated.',
    successDescription: 'You can start printing the card.',
    successButton: 'Done',
    errorTitle: 'An error occurred during activation',
    errorDescription: 'Check the correctness of the entered data. If all data is correct, try again. In case of repeated error, contact the administrator',
    errorButton: 'Try again'
  },
};
