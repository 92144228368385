import React from 'react';
import cn from 'classnames';
import InputMaskComponent from 'react-input-mask';
import { CrossIcon } from '../Icons/Icons';
import Button from '../Button/Button';
import { TextFieldPolymorphicComponent, TextFieldPropsWithRef, TextFieldProps, TextFieldRef } from './TextField.d';
import styles from './TextField.module.scss';

const TextField: TextFieldPolymorphicComponent = React.forwardRef(
    <C extends React.ElementType = 'input'>(
        {
            adornmentEnd,
            adornmentStart,
            color = 'primary',
            classes,
            disabled,
            fullWidth,
            helperText,
            hideResize,
            id,
            isDark,
            isError,
            isMultiline,
            label,
            labelEndContent,
            maxRows,
            minRows = 1,
            onChange,
            onClearClick,
            rows,
            inputWrapperRef,
            RootProps,
            mask,
            ...props
        }: TextFieldPropsWithRef<C, TextFieldProps>,
        ref: TextFieldRef<C>
    ) => {
        const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);
        const textareaHiddenRef = React.useRef<HTMLTextAreaElement>(null);

        const inputId = React.useId();

        // eslint-disable-next-line no-nested-ternary
        const Component = isMultiline ? 'textarea' : mask ? InputMaskComponent : 'input';
        const {
            root,
            label: labelClass,
            labelContainer,
            input,
            container,
            helperText: helperTextClass,
            adornmentStart: adornmentStartClass,
            adornmentEnd: adornmentEndClass,
            clearButton: clearButtonClass
        } = classes ?? {};

        const classesRoot = cn(
            styles.Root,
            styles[`Color-${color}`],
            disabled && styles.Disabled,
            fullWidth && styles.FullWidth,
            isError && styles.Error,
            isDark && styles.Dark,
            isMultiline && styles.Textarea,
            root
        );

        React.useImperativeHandle(ref, () => inputRef.current, []);

        const getLineHeight = React.useCallback(() => {
            const defaultLineHeight = 21;

            if (inputRef.current) {
                const { lineHeight } = window.getComputedStyle(inputRef.current) || `${defaultLineHeight}px`;

                return Number(lineHeight.split('px')[0]);
            }

            return defaultLineHeight;
        }, []);

        const setTextareaHeight = React.useCallback(() => {
            if (textareaHiddenRef.current && inputRef.current) {
                const { width } = window.getComputedStyle(inputRef.current);
                const currentRows = textareaHiddenRef.current.scrollHeight / getLineHeight();

                textareaHiddenRef.current.style.width = width;
                textareaHiddenRef.current.value = inputRef.current.value;

                if (!maxRows || currentRows < maxRows || (rows && maxRows <= rows)) {
                    inputRef.current.style.height = `${textareaHiddenRef.current.scrollHeight}px`;
                }
            }
        }, [getLineHeight, maxRows, rows]);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onChange?.(e);

            if (isMultiline && !rows) setTextareaHeight();
        };

        const handleClick = () => inputRef.current?.focus?.();
        const handleClearClick = () => onClearClick?.();

        React.useLayoutEffect(() => {
            if (isMultiline) setTextareaHeight();
        }, [isMultiline, setTextareaHeight]);

        return (
            <div className={cn(styles.Root, classesRoot)} {...RootProps}>
                {(label || labelEndContent) && (
                    <div className={cn(styles.LabelContainer, labelContainer)}>
                        {label && (
                            <label className={cn(styles.Label, labelClass)} htmlFor={id ?? inputId}>
                                {label}
                            </label>
                        )}
                        {labelEndContent && labelEndContent}
                    </div>
                )}
                <div
                    className={cn(styles.Container, isMultiline && styles.ContainerMultiline, container)}
                    role="button"
                    tabIndex={-1}
                    ref={inputWrapperRef}
                    onClick={handleClick}
                    onKeyDown={() => {}}
                >
                    {adornmentStart && (
                        <div className={cn(styles.Adornment, styles.AdornmentStart, adornmentStartClass)}>
                            {adornmentStart}
                        </div>
                    )}
                    <Component
                        id={id ?? inputId}
                        className={cn(
                            styles.Input,
                            (adornmentEnd || onClearClick || hideResize) && styles.InputHideResize,
                            (adornmentEnd || onClearClick) && styles.InputWithAdornmentEnd,
                            input
                        )}
                        aria-invalid={Boolean(isError)}
                        aria-describedby={id ?? inputId}
                        tabIndex={disabled ? -1 : undefined}
                        onChange={handleChange}
                        {...props}
                        ref={inputRef}
                        // eslint-disable-next-line no-unneeded-ternary
                        mask={mask ? mask : ''}
                    />
                    {isMultiline && (
                        <textarea
                            ref={textareaHiddenRef}
                            className={styles.TextareaHidden}
                            rows={rows ?? minRows}
                            tabIndex={-1}
                            readOnly
                        />
                    )}
                    {(adornmentEnd || onClearClick) && (
                        <div className={cn(styles.Adornment, styles.AdornmentEnd, adornmentEndClass)}>
                            {onClearClick && (
                                <Button
                                    size="small"
                                    color={isDark ? 'secondary' : 'gray'}
                                    isDark={isDark}
                                    type="button"
                                    iconEnd={<CrossIcon />}
                                    classes={{ root: clearButtonClass }}
                                    onClick={handleClearClick}
                                    tabIndex={disabled ? -1 : undefined}
                                    isRound
                                />
                            )}
                            {adornmentEnd}
                        </div>
                    )}
                </div>
                {helperText && <span className={cn(styles.HelperText, helperTextClass)}>{helperText}</span>}
            </div>
        );
    }
);

export default TextField;
